import React, { useEffect, useState } from "react";
import "./subscriptions.css";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Searchbox from "./../../Components/Searchbox/Searchbox";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { Edit, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import EditModal from "./EditModal";
import { formatDays, setHeaders } from "../../utils";

export default function Subscriptions() {
  const [Idd, setIdd] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [allSubscriptions, setAllSubscriptions] = useState([]);

  useEffect(() => {
    console.log("allSubscriptions", allSubscriptions);
  }, [allSubscriptions]);

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  function getAllSubscriptions() {
    const headers = setHeaders();
    axios
      .get(config.hostname + `/api/v1/Service/1,1000`, { headers })
      .then((response) => {
        setAllSubscriptions(response.data.servicesList);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function handleEditClick(id) {
    setIdd(id);
    setIsEditModalOpen(true);
  }

  function closeEditModal() {
    setIsEditModalOpen(false);
  }

  function deleteSubscription(id) {
    Swal.fire({
      icon: "question",
      title: "حذف اشتراک",
      text: "آیا از حذف اشتراک مطمئن هستید؟",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let jwt_token = "";
        const localStorageData = localStorage.getItem("user");

        if (localStorageData) {
          const parsedData = JSON.parse(localStorageData);
          jwt_token = parsedData.token;
          const headers = {
            Authorization: `Bearer ${jwt_token}`,
          };

          await axios
            .delete(config.hostname + `/api/v1/Service/${id}`, {
              headers,
            })
            .then((res) => getAllSubscriptions())

            .catch((error) => {
              console.error("خطا در درخواست به API برای حذف پزشک: ", error);
            });
        } else {
          Swal.fire({
            title: "خطا",
            text: "مشکلی در حذف اشتراک پیش آمد.",
            icon: "error",
          });
        }
      }
    });
  }

  const columns = [
    {
      field: "keyID",
      headerName: "شناسه",
      width: 90,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {
              <Link
                className="link"
                onClick={() => handleEditClick(params.row.keyID)}
              >
                <Edit className="userListEdit" />
              </Link>
            }
            {
              <Link className="link">
                <Delete
                  className="userListTrash"
                  onClick={() => deleteSubscription(params.row.keyID)}
                />
              </Link>
            }
          </>
        );
      },
    },
    {
      field: "subject",
      headerName: "عنوان",
      width: 150,
      // renderHeader: () => (
      //   <div style={{ display: "flex", alignItems: "center" }}>
      //     <span style={{ marginTop: "1px" }}>عنوان</span>
      //   </div>
      // ),
      // renderCell: (params) => {
      //   return <span>{`اشتراک ${formatDays(duration)} پزشکان`}</span>;
      // },
    },
    {
      field: "price",
      headerName: "قیمت",
      width: 130,
      renderCell: (params) => <span>{params.row.price.toLocaleString()}</span>,
    },
    {
      field: "discount",
      headerName: "تخفیف (%)",
      width: 90,
    },
    {
      field: "description",
      headerName: "توضیحات",
      width: 200,
    },
    {
      field: "isActive",
      headerName: "وضعیت",
      width: 200,
      renderCell: (params) => {
        return <span>{params.row.isActive ? "فعال" : "غیرفعال"}</span>;
      },
    },
  ];
  return (
    <main className="userList">
      <div className="education-actions">
        <Button onClick={() => handleEditClick(0)} variant="contained">
          افزودن
          <AddCircleOutlineIcon className="userListInsert" />
        </Button>
        <Searchbox />
      </div>

      <DataGrid
        rows={allSubscriptions}
        columns={columns}
        getRowId={(row) => row.keyID}
        pageSize={10}
        disableSelectionOnClick
        checkboxSelection
      />

      {isEditModalOpen && (
        <EditModal
          Idd={Idd}
          isOpen={isEditModalOpen}
          formatDays={formatDays}
          refresh={getAllSubscriptions}
          onClose={closeEditModal}
        />
      )}
    </main>
  );
}
