import React, { useState, useContext, useEffect } from "react";
import { Treebeard } from "react-treebeard";
import { Modal, Button, Typography, TextField } from "@mui/material";
import { SelectedGroup } from "../../Contexts/SelectedGroup";
//import { ExerSelectedGroup } from '../../Contexts/excerGroupContext';
import axios from "axios";
import config from "../../config.js";

import "./treeView.css";
import { setHeaders } from "../../utils.js";

const TreeView = ({ data }) => {
  const [treeData, setTreeData] = useState({ children: data });
  const [lastClickTime, setLastClickTime] = useState(0);
  const [forceUpdateFlag, setForceUpdateFlag] = useState(false); // New state to force update
  const {
    setSelectedGroup,
    selectedGroupsList,
    setSelectedGroupsList,
    updateExerSelectedGroupVariable,
  } = useContext(SelectedGroup);
  //const { ExerSelectedGroup, setExerSelectedGroup,updateExerSelectedGroupVariable } = useContext(ExerSelectedGroup);
  const [openModal, setOpenModal] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [nodeCurrent, setNodeCurrent] = useState();
  const [isEdited, setIsEdited] = useState(false);
  const forceUpdate = () => setForceUpdateFlag((prev) => !prev); // Function to toggle force update

  const [editData, setEditData] = useState({
    keyID: "",
    parentKeyID: "",
    categoryName: "",
  });

  useEffect(() => {
    setTreeData({ children: data });
  }, [data]);

  useEffect(() => {
    nodeCurrent && handleAdd(nodeCurrent);
  }, [nodeCurrent]);

  const [fetchedtdataset, setfetchedtdataset] = useState([]);

  const onToggle = (node) => {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - lastClickTime;
    console.log("//node", node);
    setNodeCurrent(node);
    // handleAdd(currentNode);
    if (elapsedTime < 300) {
      // setOpenDialog(true);
      // اگر زمان بین دو کلیک کمتر از 300 میلی‌ثانیه باشد و گره انتخاب شده ایتم اصلی باشد
      // const confirmAdd = window.confirm("آیا میخواهید این گروه را به لیست اضافه کنید؟");
      // if (confirmAdd) {
      //   setSelectedGroup(node);
      //   setSelectedGroupsList([...selectedGroupsList, node]);
      // }
      // node.style = node.style ? { ...node.style, className: 'green-text' } : { className: 'green-text' };
    } else {
      node.toggled = !node.toggled;
    }

    setLastClickTime(currentTime);

    setTreeData({ ...treeData });
    forceUpdate();
  };
  function mapdatatofetcheddata(rawData) {
    const fetchedData = [];

    const groupByKeyID = {};

    // گروه‌بندی بر اساس keyID
    rawData.forEach((item) => {
      if (item.fK_ParentID === null) {
        groupByKeyID[item.keyID] = {
          keyID: item.keyID,
          name: item.ctgName,
          children: [],
        };
      } else {
        const parent = groupByKeyID[item.fK_ParentID];
        if (parent) {
          parent.children.push({
            keyID: item.keyID,
            name: item.ctgName,
          });
        }
      }
    });

    // افزودن داده‌های گروه‌بندی شده به آرایه fetchedData
    Object.values(groupByKeyID).forEach((group) => {
      fetchedData.push(group);
    });
    setfetchedtdataset(fetchedData);
    console.log(" tereeee", fetchedData);
    return { fetchedData };
  }
  const handleclickkk = () => {
    console.log("cliiik");
    // setCurrentNode(null);
  };

  const handleRefresh = () => {
    const headers = setHeaders();
    axios
      .get(config.hostname + "/api/v1/ExerciseCtg", { headers })
      .then((response) => {
        const ctgData = response.data;
        const fetchedData = mapdatatofetcheddata(ctgData);
        setTreeData({ children: fetchedData.fetchedData });
        // Set toggled property to true for the root node
        if (fetchedData.length > 0) {
          fetchedData[0].toggled = true;
        }
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  const handleAdd = (node) => {
    console.log("node1111: ", node);
    updateExerSelectedGroupVariable({
      keyid: node.keyID,
      text: node.name,
    });
    setSelectedGroup(node);
    setSelectedGroupsList([...selectedGroupsList, node]);
    // handleModalClose()
    handleDialogClose();
  };

  const handleDelete = (node) => {
    const headers = setHeaders();
    axios
      .delete(config.hostname + `/api/v1/ExerciseCtg/${nodeCurrent.keyID}`, {
        headers,
      })

      .catch((error) => {
        console.error("خطا در درخواست به API برای حذف گروه: ", error);
      });

    handleDialogClose();
  };

  const handleCancle = () => {
    handleDialogClose();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const modalStyle = {
    position: "absolute",
    top: "60%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 16,
    backgroundColor: "white",
    borderRadius: 8,
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    // zIndex: '99999',
  };

  const handleEdit = (node) => {
    setIsEdited(true);
    setOpenModal(true);
    setCurrentNode(node);
    // if(nodeCurrent.children === null){
    setEditData({
      keyID: nodeCurrent.keyID,
      parentKeyID: "",
      categoryName: nodeCurrent.name,
    });
    // }
  };

  const handleSaveChanges = (id) => {
    try {
      const headers = setHeaders();
      axios
        .put(
          config.hostname + `/api/v1/ExerciseCtg`,
          {
            keyID: editData.keyID,
            parentKeyID: -1,
            categoryName: editData.categoryName,
          },
          { headers }
        )
        .then((response) => {
          console.log("ویرایش با موفقیت انجام شد", response.data);
          handleRefresh(); // Execute handleRefresh after successful PUT
        })
        .catch((error) => {
          console.error("خطا در ویرایش : ", error);
        });
    } catch (error) {
      console.error("خطا در ویرایش : ", error);
    }
    setIsEdited(false);
  };

  const handleModalClose = () => {
    setIsEdited(false);
    // setCurrentNode(null);
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <Treebeard
        key={JSON.stringify(treeData)}
        data={treeData}
        onToggle={onToggle}
        id="test-tree-beard"
        style={{
          tree: { base: { backgroundColor: "white", overflowY: 'auto', maxHeight: '70vh' } },
        }}
      />
      {/* 
      {currentNode && (
        <Modal open={openModal} onClose={handleModalClose}>
          <div style={modalStyle}>
            <Typography variant="h5">عملیات بر روی گروه</Typography>
            <Button variant="contained" onClick={() => handleEdit(currentNode)}>ویرایش</Button>
            <Button variant="contained" onClick={() => handleAdd(currentNode)}>اضافه کردن</Button>
            <Button variant="contained" onClick={() => handleDelete(currentNode)}>حذف</Button>
          </div>
        </Modal>
      )} */}

      {openDialog && (
        <div style={modalStyle}>
          <Typography variant="h5">عملیات بر روی گروه</Typography>
          <Button variant="contained" onClick={() => handleEdit(currentNode)}>
            ویرایش
          </Button>
          <Button variant="contained" onClick={() => handleAdd(currentNode)}>
            اضافه کردن
          </Button>
          <Button variant="contained" onClick={() => handleDelete(currentNode)}>
            {" "}
            حذف
          </Button>
          <Button variant="contained" onClick={() => handleCancle()}>
            کنسل
          </Button>
        </div>
      )}

      {isEdited && (
        <Modal open={isEdited} onClose={handleModalClose}>
          <div style={{ padding: 16 }}>
            <Typography variant="h5">عملیات بر روی گروه</Typography>
            <TextField
              label="نام"
              variant="outlined"
              fullWidth
              size="small" // اضافه کردن سایز کوچکتر
              sx={{ marginBottom: 2, backgroundColor: "#f0f0f0" }} // تغییرات در استایل
              value={editData.categoryName}
              onChange={(e) =>
                setEditData({ ...editData, categoryName: e.target.value })
              }
            />
            {/* دیگر فیلدهای ویرایش را اینجا اضافه کنید */}
            <Button variant="contained" onClick={handleSaveChanges}>
              ذخیره تغییرات
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

const MyTree = (fetchedData) => {
  console.log("rrr4", fetchedData.fetchedData);
  return (
    <TreeView data={fetchedData.fetchedData} />
  );
};

export default MyTree;
