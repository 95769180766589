import React, { useState, useEffect } from "react";
import { userRows } from "./../../datas";
import { DataGrid, useGridSelector } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios"; // Import Axios
import "./UserList.css";
import { Button } from "@mui/material";
import ModalUser from "./modal-user"; // وارد کردن فایل modal-user
import config from "../../config";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Searchbox from "../../Components/Searchbox/Searchbox";
import { setHeaders } from "../../utils";

function UsersList() {
  const [userDatas, setUserDatas] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);

  const handleafterEdit = () => {
    setEditModalOpen(false);
    refreshlist();
    // const confirmDelete3 = window.confirm(
    //   "ddddd"
    // );

    //  if (confirmDelete3)
    // بروزرسانی اطلاعات پزشک در state بر اساس شناسه پزشک
  };

  const handleEditClick = (userId) => {
    //  const confirmDele = window.confirm(userId);

    //   if (confirmDele) {

    // }

    setEditingUserId(userId);

    setEditModalOpen(true);
  };
  useEffect(() => {
    const headers = setHeaders();
    axios
      .get(config.hostname + "/api/v1/User", { headers })
      .then((response) => {
        const userData = response.data;

        const formattedData = userData.map((item) => ({
          id: item.userid,
          username: item.username,
          email: item.email,
          phoneNumber: item.phoneNumber,
        }));
        setUserDatas(formattedData);
      })

      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }, []);

  const refreshlist = () => {
    const headers = setHeaders();

    axios
      .get(config.hostname + "/api/v1/User", { headers })
      .then((response) => {
        const userData = response.data;

        const formattedData = userData.map((item) => ({
          id: item.userid,
          username: item.username,
          email: item.email,
          phoneNumber: item.phoneNumber,
        }));
        setUserDatas(formattedData);
      })

      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  const userDelete = (userId) => {
    const confirmDelete = window.confirm(
      "آیا مطمئن هستید که می‌خواهید این کاربر را حذف کنید؟"
    );

    if (confirmDelete) {
      // اگر کاربر تایید کرد، آنگاه از API برای حذف کاربر استفاده کنید
      // مثال: ارسال درخواست DELETE به API برای حذف کاربر با شناسه userId
      axios
        .delete(config.hostname + `/api/v1/User/${userId}`)
        .then((response) => {
          // در صورت موفقیت آمیز بودن عملیات حذف، به روزرسانی state یا دریافت مجدد داده‌ها از API
          // مثال: setUserDatas(updatedData) یا دریافت مجدد داده‌ها
        })
        .catch((error) => {
          console.error("خطا در درخواست به API برای حذف کاربر: ", error);
        });
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "شناسه",
      width: 300,
      display: "none",
      hide: true,
    },
    {
      field: "action",
      headerName: "عملیات رمز",
      width: 150,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            <Link
              className="link"
              onClick={() => handleEditClick(params.row.id)}
            >
              <EditIcon className="userListEdit" />
            </Link>
            {/*  <DeleteIcon 
                  className="userListTrash"
                  onClick={() => userDelete(params.row.id)}/>
                  */}
          </>
        );
      },
    },
    {
      field: "username",
      headerName: "نام کاربری",
      width: 200,
      /*  renderCell: (params) => {
                return(
                    <>
                        <Link to={`/user/${params.row.id}`} className="link">
                            <div className="userListUser">
                                <img src={params.row.avatar} className='userListImg'/>
                                {params.row.username}
                            </div>
                        </Link>
                    </>
                )
            }*/
    },
    {
      field: "email",
      headerName: "ایمیل",
      width: 200,
      hide: true,
    },
    {
      field: "phoneNumber",
      headerName: "شماره تماس",
      width: 120,
      hide: true,
    },
    /*  {
            field: 'transaction',
            headerName: 'Transaction',
            width: 160
        },*/
  ];

  return (
    <>
      <div sx={{ height: 520, width: "90%" }} className="userList">
        <div className="education-actions">
          <Button onClick={() => handleEditClick(0)} variant="contained">
            افزودن
            <AddCircleOutlineIcon className="userListInsert" />
          </Button>
          <Searchbox />
        </div>
        <DataGrid
          rows={userDatas}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          checkboxSelection
        />
      </div>

      <ModalUser
        isOpen={isEditModalOpen}
        onClose={() => handleafterEdit()}
        Idd={editingUserId}
      />
    </>
  );
}

export default UsersList;
