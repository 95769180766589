import React, { useState, useEffect } from "react";
import { DataGrid, GridMenuIcon } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { Edit, Delete, AddCircleOutline } from "@mui/icons-material";
import axios from "axios";
import { Button } from "@mui/material";
import Modaledu from "./modal-edu";
import config from "../../config";
import ModalAttachEduFile from "./modalAttachEduFile";
import Searchbox from "./../../Components/Searchbox/Searchbox";

import "./EducationList.css";
import { setHeaders } from "../../utils";
import ModalGroup from "./ModalGroup";

function EducationList() {
  const [educationList, seteducationList] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingeducationId, setEditingeducationId] = useState(null);
  const [isAttachModalOpen, setAttachModalOpen] = useState(false);
  const [AttachModalID, setAttachEduId] = useState(false);

  const [EDUIDForModalGroup, setEDUIDForModalGroup] = useState(false);
  const [isGroupModalOpen, setGroupModalOpen] = useState(false);

  const handleEditClick = (educationId) => {
    setEditingeducationId(educationId);

    setEditModalOpen(true);
  };
  const handleAttachFilesClick = (educationId) => {
    setAttachEduId(educationId);
    setAttachModalOpen(true);
  };
  const handleafterAttach = () => {
    setAttachModalOpen(false);
  };

  const handleafterEdit = () => {
    setEditModalOpen(false);
    refreshlist();
  };

  const refreshlist = () => {
    const headers = setHeaders();
    axios
      .get(config.hostname + "/api/v1/Education/-1,1,1000,-1", { headers })
      .then((response) => {
        const educationData = response.data["educationlist"];

        const formattedData = educationData.map((item) => ({
          id: item.keyID,
          title: item.title,
          content: item.content,
          subTitle: item.subTitle,
          subContent: item.subContent,
        }));
        seteducationList(formattedData);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  useEffect(() => {
    const headers = setHeaders();
    axios
      .get(config.hostname + "/api/v1/Education/-1,1,1000,-1", { headers })
      .then((response) => {
        const educationData = response.data["educationlist"];

        const formattedData = educationData.map((item) => ({
          id: item.keyID,
          title: item.title,
          content: item.content,
          subTitle: item.subTitle,
          subContent: item.subContent,
        }));
        seteducationList(formattedData);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }, []);

  const deleteeducation = async (educationId) => {
    const confirmDelete = window.confirm(
      "آیا مطمئن هستید که می‌خواهید این آموزش را حذف کنید؟"
    );

    if (confirmDelete) {
      const headers = setHeaders();
      await axios
        .delete(config.hostname + `/api/v1/education/${educationId}`, {
          headers,
        })

        .catch((error) => {
          console.error("خطا در درخواست به API برای آموزش پزشک: ", error);
        });
      refreshlist();
    }
  };

  const handleGroupClick = (educationId) => {
    setEDUIDForModalGroup(educationId);

    setGroupModalOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "شناسه",
      width: 90,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {
              <Link
                className="link"
                onClick={() => handleEditClick(params.row.id)}
              >
                <Edit className="userListEdit" />
              </Link>
            }
            {
              <Link className="link">
                <Delete
                  className="userListTrash"
                  onClick={() => deleteeducation(params.row.id)}
                />
              </Link>
            }
            {
              <Link className="link">
                <img
                  src="/images/attachicon.png"
                  alt="attach"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  onClick={() => handleAttachFilesClick(params.row.id)}
                />
              </Link>
            }
            {
              <Link className="link">
                <img
                  src="/images/hashtag.png"
                  alt="attach"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  onClick={() => handleGroupClick(params.row.id)}
                />
              </Link>
            }
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "عنوان",
      width: 150,
    },
    {
      field: "content",
      headerName: "محتوای مختصر",
      width: 150,
    },
    {
      field: "subTitle",
      headerName: "زیرعنوان",
      width: 150,
    },
    {
      field: "subContent",
      headerName: "شرح",
      width: 200,
    },
  ];

  return (
    <>
      <div className="userList">
        <div className="education-actions">
          <Button onClick={() => handleEditClick(0)} variant="contained">
            افزودن
            <AddCircleOutline className="userListInsert" />
          </Button>
          <Searchbox />
        </div>

        <DataGrid
          rows={educationList}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          checkboxSelection
        />
      </div>

      <Modaledu
        isOpen={isEditModalOpen}
        onClose={handleafterEdit}
        Idd={editingeducationId}
      />
      <ModalAttachEduFile
        isOpen={isAttachModalOpen}
        onClose={handleafterAttach}
        Idd={AttachModalID}
      />

      <ModalGroup
        isOpen={isGroupModalOpen}
        onClose={() => setGroupModalOpen(false)}
        Idd={EDUIDForModalGroup}
      />
    </>
  );
}

export default EducationList;
