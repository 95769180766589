import React, { useEffect, useState } from "react";
import { setHeaders } from "../../utils";
import axios from "axios";
import config from "../../config";
import { DataGrid } from "@mui/x-data-grid";
import Searchbox from "../../Components/Searchbox/Searchbox";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedIcon from "@mui/icons-material/Verified";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog/Dialog";

import "./UnverifiedDoctors.css";

export default function UnverifiedDoctors() {
  const [verifyDoctorID, setVerifyDoctorID] = useState(null);
  const [physicianList, setPhysicianList] = useState([]);
  const [doctorFiles, setDoctorFiles] = useState([]);

  useEffect(() => {
    getAllDoctors();
  }, []);

  useEffect(() => {
    if (verifyDoctorID) {
      const headers = setHeaders();

      axios
        .get(
          config.hostname +
            `/api/v1/Physician/${verifyDoctorID}/PhysicianFiles`,
          { headers }
        )
        .then((res) => setDoctorFiles(res.data.result));
    }
  }, [verifyDoctorID]);

  const columns = [
    {
      field: "id",
      headerName: "شناسه ",
      width: 90,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {
              <Link
                className="link"
                onClick={() => setVerifyDoctorID(params.row.id)}
              >
                <VerifiedIcon className="userListEdit" />
              </Link>
            }
            {
              <Link className="link">
                <DeleteIcon
                  className="userListTrash"
                  onClick={() => handleRemoveClick(params.row.id)}
                />
              </Link>
            }
          </>
        );
      },
    },
    {
      field: "fName",
      headerName: "نام",
      width: 150,
    },
    {
      field: "lName",
      headerName: "نام خانوادگی",
      width: 150,
    },
    {
      field: "nName",
      headerName: "نام مستعار",
      width: 150,
    },
    {
      field: "medicalLicense",
      headerName: "مجوز پزشکی",
      width: 200,
      hide: true,
    },
    {
      field: "nationalCode",
      headerName: "کد ملی",
      width: 150,
      hide: true,
    },
    {
      field: "gender",
      headerName: "جنسیت",
      width: 120,
      hide: true,
    },
    {
      field: "mobile1",
      headerName: "موبایل",
      width: 150,
    },
    {
      field: "mobile2",
      headerName: "موبایل 2",
      width: 150,
      hide: true,
    },
    {
      field: "email",
      headerName: "ایمیل",
      width: 200,
    },
    {
      field: "description",
      headerName: "توضیحات",
      width: 150,
      hide: true,
    },
    {
      field: "speciallization",
      headerName: "تخصص",
      width: 150,
      hide: true,
    },
  ];

  function getAllDoctors() {
    const headers = setHeaders();
    axios
      .get(
        config.hostname +
          "/api/v1/Physician/GetNewUserByPage?pageNumber=1&pageSize=10",
        { headers }
      )
      .then((res) => {
        const physicianData = res.data.physicianModelList;

        const formattedData = physicianData.map((item) => ({
          id: item.keyID,
          fName: item.fName,
          lName: item.lName,
          nName: item.nName,
          medicalLicense: item.medicalLicense,
          nationalCode: item.nationalCode,
          birthDate: item.birthDate,
          gender: item.gender,
          mobile1: item.mobile1,
          mobile2: item.mobile2,
          email: item.email,
          description: item.description,
          speciallization: item.speciallization,
        }));
        setPhysicianList(formattedData);
      });
  }

  function handleRemoveClick(id) {
    Swal.fire({
      icon: "question",
      title: "آیا از حذف پزشک مطمئن هستید؟",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        removeDoctor(id);
      }
    });
  }

  function removeDoctor(id) {
    const headers = setHeaders();

    axios
      .delete(config.hostname + `/api/v1/Physician/${id}`, { headers })
      .then((res) => {
        getAllDoctors();
      });
  }

  function handleVerifyClick() {
    Swal.fire({
      icon: "question",
      title: "آیا میخواهید این پزشک رو تایید کنید؟",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        verifyDoctor(verifyDoctorID);
      }
    });
  }

  function verifyDoctor(userID) {
    const headers = setHeaders();
    axios
      .put(
        config.hostname +
          `/api/v1/Physician/VerifyRole?physicianKeyID=${userID}`,
        null,
        { headers }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "مدارک پزشک تایید شد.",
          confirmButtonText: "باشه",
        });
        getAllDoctors();
        setVerifyDoctorID(null);
      });
  }

  return (
    <>
      <div sx={{ height: 520, width: "90%" }} className="userList">
        <DataGrid
          rows={physicianList}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          checkboxSelection
        />
      </div>

      <Dialog
        className="verify-doctor-dialog"
        open={verifyDoctorID}
        onClose={() => setVerifyDoctorID(null)}
      >
        <div className="verify-doctor-container">
          {doctorFiles.length > 0 &&
            doctorFiles.map((file) => (
              <div className="single-doctor-file">
                <span>
                  {file.fileDescription === "nationalcard"
                    ? "کارت ملی"
                    : "کارت نظام پزشکی"}
                </span>
                <img src={file.filesAddress} alt={file.fileDescription} />
              </div>
            ))}
        </div>
        <button onClick={handleVerifyClick}>تایید مدارک</button>
      </Dialog>
    </>
  );
}
