import React from "react";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import TimelineIcon from "@mui/icons-material/Timeline";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import MessageIcon from "@mui/icons-material/Message";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ReportIcon from "@mui/icons-material/Report";

import { NavLink } from "react-router-dom";

import "./Sidebar.css";

function Sidebar() {
  return (
    <>
      <div className="sidebar">
        <div className="sidbarWrapper">
          <div className="sidebarMenue">
            <h3 className="sidebarTitle">داشبورد</h3>
            <ul className="sidebarList">
              <li className="sidebarListItem">
                <NavLink to="/" className="link">
                  <LineStyleIcon />
                  خانه
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="sidebarMenue">
            <h3 className="sidebarTitle">منوی دسترسی</h3>
            <ul className="sidebarList">
              <li className="sidebarListItem">
                <NavLink to="/users" className="link">
                  <PermIdentityIcon />
                  کاربران
                </NavLink>
              </li>
              <li className="sidebarListItem">
                <NavLink to="/physician" className="link">
                  <PermIdentityIcon />
                  پزشکان
                </NavLink>
              </li>
              <li className="sidebarListItem">
                <NavLink to="/unverifiedDoctors" className="link">
                  <PermIdentityIcon />
                  پزشکان تایید نشده
                </NavLink>
              </li>
              <li className="sidebarListItem">
                <NavLink to="/patient" className="link">
                  <PermIdentityIcon />
                  بیماران
                </NavLink>
              </li>
              <li className="sidebarListItem">
                <NavLink to="/Exercise" className="link">
                  <StorefrontIcon />
                  تمرینات
                </NavLink>
              </li>
              <li className="sidebarListItem">
                <NavLink to="/Education" className="link">
                  <StorefrontIcon />
                  آموزش ها
                </NavLink>
              </li>
              <li className="sidebarListItem">
                <NavLink to="/subscriptions" className="link">
                  <StorefrontIcon />
                  اشتراک ها
                </NavLink>
              </li>
              <li className="sidebarListItem">
                <NavLink to="/tickets" className="link">
                  <StorefrontIcon />
                  مدیریت تیکت ها
                </NavLink>
              </li>
              <li className="sidebarListItem">
                <NavLink to="/offs" className="link">
                  <StorefrontIcon />
                  تخفیف ها
                </NavLink>
              </li>
              <li className="sidebarListItem">
                <NavLink to="/orders" className="link">
                  <StorefrontIcon />
                  سفارش ها
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
