import React, { useState, useEffect } from "react";
//import { Modal, Paper, TextField, Button } from "@mui/material";
//import React, { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
//import axios from "axios";
import axios from "axios";
import { Email } from "@mui/icons-material";
import config from "../../config";
import { setHeaders } from "../../utils";

function ModalUser({ isOpen, onClose, Idd }) {
  const [fName, setfName] = useState(null);
  const [lName, setlName] = useState(null);
  const [nName, setnName] = useState(null);
  const [medicalLicense, setmedicalLicense] = useState(null);
  const [nationalCode, setnationalCode] = useState(null);
  const [birthDate, setbirthDate] = useState(null);
  const [gender, setgender] = useState(null);
  const [mobile1, setmobile1] = useState(null);
  const [mobile2, setmobile2] = useState(null);
  const [email, setemail] = useState(null);
  const [description, setdescription] = useState(null);
  const [speciallization, setspeciallization] = useState(null);
  const [tell, setTell] = useState();
  const [address, setAddress] = useState(null);
  const [fax, setFax] = useState(null);
  const [cityid, setCityId] = useState(null);
  const [postal, setPostal] = useState(null);
  const [male, setMale] = useState(null);
  const [female, setFemale] = useState(null);
  const famousCities = ["رشت", "زاهدان", "تهران", "مشهد", "تبریز"];
  useEffect(() => {
    //const confirmDelete3 = window.confirm(
    //Idd
    //  );

    ///  if (confirmDelete3) {

    // }
    if (Idd > 0) {
      const headers = setHeaders();
      axios
        .get(config.hostname + `/api/v1/Physician/${Idd}`, { headers })
        .then((response) => {
          const physicianData = response.data;
          setfName(physicianData.fName);
          setlName(physicianData.lName);
          setnName(physicianData.nName);
          setmedicalLicense(physicianData.medicalLicense);
          setnationalCode(physicianData.nationalCode);
          setbirthDate(physicianData.birthDate);
          setgender(physicianData.gender);
          setmobile1(physicianData.mobile1);
          setmobile2(physicianData.mobile2);
          setemail(physicianData.email);
          setdescription(physicianData.description);
          setspeciallization(physicianData.speciallization);
          setTell(physicianData.tell);
          setAddress(physicianData.address);
          setFax(physicianData.fax);
          setCityId(famousCities[physicianData.cityId - 1]);
          setPostal(physicianData.postalCode);
          if (physicianData.gender == 1) {
            setFemale(true);
            setMale(false);
          } else {
            setFemale(false);
            setMale(true);
          }
          /*    const formattedData = physicianData.map((item) => ({
            id: item.keyID,
            fName: item.fName,
            lName: item.lName,
            nName: item.nName,
            medicalLicense: item.medicalLicense,
            nationalCode: item.nationalCode,
            birthDate: item.birthDate,
            gender: item.gender,
            mobile1: item.mobile1,
            mobile2: item.mobile2,
            email: item.email,
            description: item.description,
            speciallization: item.speciallization,
          }));*/
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      setfName("");
      setlName("");
      setnName("");
      setmedicalLicense("");
      setnationalCode("");
      setbirthDate("");
      setgender("");
      setmobile1("");
      setmobile2("");
      setemail("");
      setdescription("");
      setspeciallization("");
      setTell("");
      setAddress("");
      setFax("");
      setCityId("");
      setPostal("");
    }
  }, [Idd]);

  const updateUser = async () => {
    try {
      const headers = setHeaders();

      const response = await axios.put(
        config.hostname + "/api/v1/Physician",
        {
          keyID: Idd,
          fName: fName,
          lName: lName,
          nName: nName,
          medicalLicense: medicalLicense,
          nationalCode: nationalCode,
          birthDate: birthDate,
          gender: female ? 1 : 2,

          mobile1: mobile1,
          mobile2: mobile2,
          email: email,
          description: description,
          speciallization: speciallization,
          tell: tell,
          fax: fax,
          postalCode: postal,
          cityid: famousCities.indexOf(cityid) + 1,
          address: address,
        },
        { headers }
      );

      console.log("ویرایش با موفقیت انجام شد", response.data);

      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  const insertuser = async () => {
    try {
      const headers = setHeaders();

      const response = await axios.post(
        config.hostname + `/api/v1/Physician`,
        {
          keyID: -1,
          fName: fName,
          lName: lName,
          nName: nName,
          medicalLicense: medicalLicense,
          nationalCode: nationalCode,
          birthDate: "2023-11-02T06:57:07.150Z",
          gender: 0,
          mobile1: mobile1,
          mobile2: mobile2,
          email: email,
          description: description,
          speciallization: speciallization,
          cityid: famousCities.indexOf(cityid) + 1,
          postalCode: postal,
          tell: tell,
          fax: fax,
          address: address,
        },
        { headers }
      );

      console.log("ایجاد با موفقیت انجام شد", response.data);

      onClose();
    } catch (error) {
      const confirmDelete4 = window.confirm("خطا در ثبت اطلاعات پزشک-لطفا شماره همراه و کد ملی را بررسی نمایید. شماره تلفن و کد ملی تکراری در دیتابیس قابل قبول نیست");

      if (confirmDelete4) {
      }
      console.error("خطا در ایجا د 1: ", error);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd > 0 ? "ویرایش پزشک" : " ایجاد پزشک"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
          <TextField
            itemID="fName"
            label="نام"
            value={fName}
            onChange={(e) => setfName(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="lName"
            label="نام خانوادگی"
            value={lName}
            onChange={(e) => setlName(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="nName"
            label=" نام کاربری"
            value={nName}
            onChange={(e) => setnName(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" ,display:"none"}}
          />
          <TextField
            itemID="medicalLicense"
            label="مجوز پزشکی"
            value={medicalLicense}
            onChange={(e) => setmedicalLicense(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="nationalCode"
            label="کد ملی"
            value={nationalCode}
            onChange={(e) => setnationalCode(e.target.value)}
            fullWidth
            style={{ marginTop: "10px",display:"none" }}
          />

          <TextField
            itemID="birthDate"
            label="تاریخ تولد"
            value={birthDate}
            onChange={(e) => setbirthDate(e.target.value)}
            fullWidth
            style={{ marginTop: "10px",display:"none" }}
          />
          <FormControl>
            <FormLabel>جنسیت</FormLabel>
            <RadioGroup
              row
              aria-label="gender"
              name="gender"
              value={male ? "male" : female ? "female" : ""}
              onChange={(e) => {
                if (e.target.value === "male") {
                  setMale(true);
                  setFemale(false);
                } else if (e.target.value === "female") {
                  setMale(false);
                  setFemale(true);
                } else {
                  setMale(false);
                  setFemale(false);
                }
              }}
            >
              <FormControlLabel value="male" control={<Radio />} label="مرد" />
              <FormControlLabel value="female" control={<Radio />} label="زن" />
            </RadioGroup>
          </FormControl>

          <TextField
            itemID="mobile1"
            label="موبایل "
            value={mobile1}
            onChange={(e) => setmobile1(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="mobile2"
            label="موبایل  دوم"
            value={mobile2}
            onChange={(e) => setmobile2(e.target.value)}
            fullWidth
            style={{ marginTop: "10px",display:"none" }}
          />
        </div>
        <div style={{ flex: 1, padding: "0 10px" }}>
          <TextField
            itemID="email"
            label="ایمیل"
            value={email}
            onChange={(e) => setemail(e.target.value)}
            fullWidth
            style={{ marginTop: "65px" }}
          />

          <TextField
            itemID="description"
            label="توضیحات"
            value={description}
            onChange={(e) => setdescription(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="speciallization"
            label="تخصص"
            value={speciallization}
            onChange={(e) => setspeciallization(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="tell"
            label="تلفن"
            value={tell}
            onChange={(e) => setTell(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="address"
            label="آدرس"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="fax"
            label="فکس"
            value={fax}
            onChange={(e) => setFax(e.target.value)}
            fullWidth
            style={{ marginTop: "10px",display:"none" }}
          />
          <TextField
            itemID="postal"
            label="کد پستی"
            value={postal}
            onChange={(e) => setPostal(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" ,display:"none"}}
          />
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <FormLabel>شهر</FormLabel>
            <Select
              value={cityid}
              onChange={(e) => setCityId(e.target.value)}
              fullWidth
            >
              {famousCities.map((city, index) => (
                <MenuItem key={index} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={Idd > 0 ? updateUser : insertuser}
            sx={{ marginTop: 2 }}
          >
            {Idd > 0 ? "ویرایش پزشک" : "ایجاد پزشک"}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalUser;
