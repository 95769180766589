import moment from "jalali-moment";

export function formatDate(dateString) {
  const jalaliDate = moment(dateString, "YYYY-MM-DDTHH:mm:ss.SSSZ")
    .locale("fa")
    .format("YYYY/MM/DD");

  return jalaliDate;
}

export function formatTime(dateString) {
  const formattedTime = moment(dateString).format("HH:mm");

  return formattedTime;
}

export function addDaysToDate(dateString, daysToAdd) {
  const date = new Date(dateString);

  date.setDate(date.getDate() + daysToAdd);

  return date.toISOString();
}

export function getDaysDifference(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  const timeDifference = Math.abs(date2 - date1);

  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}

export function formatDays(days) {
  const years = Math.floor(days / 365);
  days %= 365;
  const months = Math.floor(days / 30);
  days %= 30;

  let result = [];

  if (years > 0) result.push(`${years} سال`);
  if (months > 0) result.push(`${months} ماه`);
  if (days > 0) result.push(`${days} روز`);

  return result.join(" و ");
}

export function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error("Invalid token", e);
    return null;
  }
}

export function isUserAdmin() {
  const localStorageData = localStorage.getItem("user");
  if (!localStorageData) return false;

  const parsedData = JSON.parse(localStorageData);
  const token = parsedData.token;
  if (!token) return false;

  const decodedToken = parseJwt(token);

  if (!decodedToken) return false;

  const userRole =
    decodedToken[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];

  if (userRole !== "Admin") {
    return false;
  }

  return true;
}

export function isAuthenticated() {
  const localStorageData = localStorage.getItem("user");
  if (!localStorageData) return false;

  const parsedData = JSON.parse(localStorageData);
  const token = parsedData.token;
  if (!token) return false;

  const decodedToken = parseJwt(token);

  if (!decodedToken) return false;

  const currentTime = Date.now() / 1000;
  if (decodedToken.exp < currentTime) {
    localStorage.removeItem("token");
    return false;
  }
  return true;
}

export function setHeaders() {
  let jwt_token = "";
  const localStorageData = localStorage.getItem("user");
  if (localStorageData) {
    const parsedData = JSON.parse(localStorageData);
    jwt_token = parsedData.token;
  } else {
    jwt_token = "no";
  }
  const authToken = jwt_token;
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  return headers;
}
