import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import config from "../../config";
import Searchbox from "../../Components/Searchbox/Searchbox";
import { formatDate, setHeaders } from "../../utils";
import EditModal from "./EditModal";
import "./Offs.css";
import Swal from "sweetalert2";

function Offs() {
  const [offsList, setOffsList] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingOffID, setEditingOffID] = useState(null);

  useEffect(() => {
    getAllOffs();
  }, []);

  function handleEditClick(offID) {
    setEditingOffID(offID);
    setEditModalOpen(true);
  }

  function getAllOffs() {
      const headers = setHeaders();
    axios
      .get(config.hostname + "/api/v1/Discount/1,2000", { headers })
      .then((response) => {
        setOffsList(response.data.discountadminmodel);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function deleteOff(offID) {
    Swal.fire({
      icon: "question",
      title: "آیا از حذف تخفیف مطمئن هستید؟",
      confirmButtonText: "بله",
      showCancelButton: true,
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = setHeaders();
        axios
          .delete(config.hostname + `/api/v1/Discount/${offID}`, {
            headers,
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "تخفیف با موفقیت حذف شد.",
            });
            getAllOffs();
          })

          .catch((error) => {
            console.error("خطا در درخواست به API برای حذف تخفیف: ", error);
          });
      }
    });
  }

  const columns = [
    {
      field: "keyId",
      headerName: "شناسه ",
      width: 70,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 80,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {
              <Link
                className="link"
                onClick={() => handleEditClick(params.row.keyId)}
              >
                <EditIcon className="userListEdit" />
              </Link>
            }
            {
              <Link className="link">
                <DeleteIcon
                  className="userListTrash"
                  onClick={() => deleteOff(params.row.keyId)}
                />
              </Link>
            }
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "عنوان تخفیف",
      width: 150,
    },
    {
      field: "code",
      headerName: "کد تخفیف",
      width: 150,
    },
    {
      field: "discountPercent",
      headerName: "درصد تخفیف",
      width: 80,
      renderCell: (params) => <span>{params.row.discountPercent}%</span>,
    },
    {
      field: "startDate",
      headerName: "تاریخ شروع",
      width: 100,
      renderCell: (params) => {
        const newDate = formatDate(params.row.startDate);
        return <span>{newDate}</span>;
      },
    },
    {
      field: "endDate",
      headerName: "تاریخ پایان",
      width: 100,
      renderCell: (params) => {
        const newDate = formatDate(params.row.endDate);
        return <span>{newDate}</span>;
      },
    },
    {
      field: "isActiveForAll",
      headerName: "فعال برای همه",
      width: 120,
      renderCell: (params) => (
        <span>{params.row.isActiveForAll ? "فعال" : "غیر فعال"}</span>
      ),
    },
    {
      field: "isActive",
      headerName: "وضعیت",
      width: 80,
      renderCell: (params) => (
        <span>{params.row.isActive ? "فعال" : "غیر فعال"}</span>
      ),
    },
    {
      field: "description",
      headerName: "توضیحات",
      width: 150,
    },
  ];

  return (
    <>
      <div sx={{ height: 520, width: "90%" }} className="userList">
        <div className="education-actions">
          <Button onClick={() => handleEditClick(0)} variant="contained">
            افزودن
            <AddCircleOutlineIcon className="userListInsert" />
          </Button>
          <Searchbox />
        </div>
        <DataGrid
          rows={offsList}
          getRowId={(row) => row.keyId}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          checkboxSelection
        />
      </div>

      {isEditModalOpen && (
        <EditModal
          Idd={editingOffID}
          isOpen={isEditModalOpen}
          onClose={() => setEditModalOpen(false)}
          refresh={getAllOffs}
        />
      )}
    </>
  );
}

export default Offs;
