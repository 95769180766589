import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import config from "../../config";
import { formatDate, formatTime, setHeaders } from "../../utils";
import { Box, CircularProgress } from "@mui/material";

export default function ActiveTicket({ activeChat, resetChat, allChatsRef }) {
  const [opponent, setopponent] = useState({
    userID: activeChat?.reciverID,
    fullname: "",
  });
  const [starterUser, setStarterUser] = useState({
    userID: activeChat?.createByUserID,
    fullname: "",
  });

  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    const headers = setHeaders();
    console.log("activeChat", activeChat);

    getContacts(headers, setStarterUser, starterUser);
    getContacts(headers, setopponent, opponent);
  }, []);

  function sendMessage(e) {
    e.preventDefault();
    const headers = setHeaders();

    const newMessageObj = {
      keyID: 0,
      content: newMessage,
    };

    axios
      .post(
        config.hostname + `/api/v1/Ticket/${activeChat.keyID}/Messsages`,
        newMessageObj,
        {
          headers,
        }
      )
      .then((response) => {
        setNewMessage("");
        resetChat(activeChat.keyID);
        // allChatsRef.current.scrollTop = allChatsRef.current.scrollHeight;
      })
      .catch((error) => console.log("send meessage error", error));
  }

  function getFullname(fname, lname) {
    return fname === "admin" && lname === "admin" ? "شما" : `${fname} ${lname}`;
  }

  function getPhysicianByUserID(headers, userID) {
    return new Promise((resolve, reject) => {
      axios
        .get(config.hostname + `/api/v1/Physician/Getbyguid?userid=${userID}`, {
          headers,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function getPatientByUserID(headers, userID) {
    return new Promise((resolve, reject) => {
      axios
        .get(config.hostname + `/api/v1/Patient/Getbyguid?userID=${userID}`, {
          headers,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function getContacts(headers, setContact, contact) {
    getPhysicianByUserID(headers, contact.userID)
      .then(({ data }) =>
        setContact((prevState) => ({
          ...prevState,
          fullname: getFullname(data.fName, data.lName),
        }))
      )
      .catch((err) =>
        getPatientByUserID(headers, contact.userID)
          .then(({ data }) => {
            setContact((prevState) => ({
              ...prevState,
              fullname: getFullname(data.fName, data.lName),
            }));
          })
          .catch((err) => console.log("second error", err))
      );
  }

  return (
    <div className="chat-container">
      {activeChat ? (
        <div className="chat-wrapper">
          <div>
            <div className="chat-title">
              <span>{activeChat.subject}</span>
            </div>

            <div className="all-chats" ref={allChatsRef}>
              {starterUser.fullname && opponent.fullname ? (
                activeChat.messages.map((message) => (
                  <div
                    key={message.keyID}
                    className={`chat modal ${
                      message.senderID === starterUser.userID
                        ? "sent"
                        : "received"
                    }`}
                  >
                    <div className="chat-header">
                      <span className="message-owner">
                        {message.senderID === starterUser.userID
                          ? starterUser.fullname
                          : opponent.fullname}
                      </span>
                      <div className="chat-date-time">
                        <span>{formatTime(message.createDate)} | </span>
                        <span>{formatDate(message.createDate)}</span>
                      </div>
                    </div>
                    <p>{message.content}</p>
                  </div>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    margin: "1rem",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </div>
          </div>
          <form>
            <button type="submit" onClick={sendMessage}>
              <SendIcon />
            </button>
            <input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              type="text"
              placeholder="پیام خود را بنویسید"
            />
          </form>
        </div>
      ) : null}
    </div>
  );
}
