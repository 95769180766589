import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Searchbox from "./../../Components/Searchbox/Searchbox";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { Edit, Delete, Cancel } from "@mui/icons-material";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import NewTicket from "./NewTicket";

import "./Tickets.css";
import ActiveTicket from "./ActiveTicket";

import { formatDate, formatTime, setHeaders } from "../../utils";

export default function Tickets() {
  const [isNewTicketOpen, setIsNewTicketOpen] = useState(false);
  const [activeTicket, setActiveTicket] = useState(null);
  const allChatsRef = useRef();
  const [allTickets, setAllTickets] = useState([]);

  useEffect(() => {
    getAllTickets();
  }, []);

  function deleteTicket(id) {
    Swal.fire({
      icon: "question",
      title: "حذف تیکت",
      text: "آیا از حذف تیکت مطمئن هستید؟",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let jwt_token = "";
        const localStorageData = localStorage.getItem("user");

        if (localStorageData) {
          const parsedData = JSON.parse(localStorageData);
          jwt_token = parsedData.token;
          const headers = {
            Authorization: `Bearer ${jwt_token}`,
          };

          // await axios
          //   .delete(config.hostname + `/api/v1/education/${educationId}`, {
          //     headers,
          //   })

          //   .catch((error) => {
          //     console.error("خطا در درخواست به API برای حذف پزشک: ", error);
          //   });
        } else {
          Swal.fire({
            title: "خطا",
            text: "مشکلی در حذف تیکت پیش آمد.",
            icon: "error",
          });
        }
      }
    });
  }

  function getAllTickets() {
    const headers = setHeaders();
    axios
      .get(config.hostname + "/api/v1/Ticket/1,10", { headers })
      .then((response) => {
        setAllTickets(response.data.ticketslist);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function showTicket(id) {
    const headers = setHeaders();
    axios
      .get(config.hostname + `/api/v1/Ticket/${id}`, { headers })
      .then((response) => {
        setActiveTicket(response.data);
        console.log("allChatsRef", allChatsRef);
        allChatsRef.current.scrollTop = allChatsRef.current.scrollHeight;
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  const columns = [
    {
      field: "keyID",
      headerName: "شناسه",
      width: 90,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {
              <Link
                className="link"
                onClick={() => showTicket(params.row.keyID)}
              >
                <QuestionAnswerIcon className="userListEdit" />
              </Link>
            }
            {/* {
              <Link className="link">
                <Delete
                  className="userListTrash"
                  onClick={() => deleteTicket(params.row.keyID)}
                />
              </Link>
            } */}
          </>
        );
      },
    },
    {
      field: "subject",
      headerName: "موضوع",
      width: 150,
    },
    {
      field: "createDate",
      headerName: "تاریخ ایجاد",
      width: 150,
      renderCell: (params) => {
        return (
          <span>
            {formatTime(params.row.createDate)} |{" "}
            {formatDate(params.row.createDate)}
          </span>
        );
      },
    },
    {
      field: "changeDate",
      headerName: "تاریخ آپدیت",
      width: 200,
      renderCell: (params) => {
        return (
          <span>
            {formatTime(params.row.changeDate)} |{" "}
            {formatDate(params.row.changeDate)}
          </span>
        );
      },
    },
    {
      field: "hasNewmessage",
      headerName: "وضعیت",
      width: 200,
      renderCell: (params) => {
        return <span>{params.row.hasNewmessage ? "جدید" : "خوانده شده"}</span>;
      },
    },
  ];

  return (
    <main className="userList">
      <div className="education-actions">
        <Button onClick={() => setIsNewTicketOpen(true)} variant="contained">
          افزودن
          <AddCircleOutlineIcon className="userListInsert" />
        </Button>
        <Searchbox />
      </div>

      <DataGrid
        rows={allTickets}
        getRowId={(row) => row.keyID}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        checkboxSelection
      />

      <NewTicket
        getAllTickets={getAllTickets}
        isNewTicketBoxOpen={isNewTicketOpen}
        setIsNewTicketBoxOpen={setIsNewTicketOpen}
      />

      <Dialog
        className="chat-box-dialog"
        open={activeTicket}
        onClose={() => setActiveTicket(null)}
      >
        <ActiveTicket
          allChatsRef={allChatsRef}
          resetChat={showTicket}
          activeChat={activeTicket}
        />
      </Dialog>
    </main>
  );
}
