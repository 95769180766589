import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function SelectBox({
  filterName,
  filterValue,
  setFilterValue,
  menuItems,
  children,
}) {
  return (
    <FormControl
      className="select-box"
      fullWidth
      variant="outlined"
      margin="normal"
    >
      <InputLabel id="filter-label" style={{ backgroundColor: "#F8F8F8" }}>
        {filterName}
      </InputLabel>

      <Select
        labelId="filter-label"
        value={filterValue}
        onChange={(e) => setFilterValue(e.target.value)}
        label="فیلتر براساس دسته بندی"
        style={{ fontFamily: "IRANSans", fontSize: "1.4rem" }}
      >
        {children}
        {menuItems?.map((item) => (
          <MenuItem
            key={item.keyID || item.id}
            value={item.userid || item.value}
          >
            {item.fName ? `${item.fName} ${item.lName}` : item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
