import Home from "./pages/Home/Home";
import PhysicianList from "./pages/Physicians/PhysiciansList";
import PatientList from "./pages/Patients/PatientsList";
import UserList from "./pages/Users/UsersList";
import Exerciselist from "./pages/Exercises/Exerciseslist";
import Educationlist from "./pages/Educations/Educationslist";
import Products from "./pages/Products/Products";
import Product from "./pages/Product/Product";
import SignInSide from "./pages/LoginPage/Login";
import Subscriptions from "./pages/Subscriptions/Subscriptions";
import Tickets from "./pages/Tickets/Tickets";
import UnverifiedDoctors from "./pages/UnverifiedDoctors/UnverifiedDoctors";
import Offs from "./pages/Offs/Offs";
import Orders from "./pages/Orders/Orders";

let routes = [
  { path: "/", element: <Home /> },
  { path: "/users", element: <UserList /> },
  { path: "/physician", element: <PhysicianList /> },
  { path: "/unverifiedDoctors", element: <UnverifiedDoctors /> },
  { path: "/patient", element: <PatientList /> },
  { path: "/Exercise", element: <Exerciselist /> },
  { path: "/Education", element: <Educationlist /> },
  { path: "/packages", element: <Products /> },
  { path: "/product/:id", element: <Product /> },
  { path: "/login", element: <SignInSide /> },
  { path: "/subscriptions", element: <Subscriptions /> },
  { path: "/tickets", element: <Tickets /> },
  { path: "/offs", element: <Offs /> },
  { path: "/orders", element: <Orders /> },
];

export default routes;
