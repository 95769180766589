import React, { useState, useEffect } from "react";
//import { Modal, Paper, TextField, Button } from "@mui/material";
//import React, { useState, useEffect } from "react";
import { Modal, Paper, TextField, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Select, MenuItem } from "@mui/material";
//import axios from "axios";
import axios from "axios";
import { Email } from "@mui/icons-material";
import config from "../../config"
import FileUploader2 from '../../Components/files/FileUploader2';
import PersianDatePicker from '../../Components/PersianDatePicker';
import jalaliMoment from 'jalali-moment';
import DisplayImage from './DisplayImage';
import FileUploader3 from '../../Components/files/FileUploader3';
import TextFieldwithAddIcon from '../../Components/TextFildwithAddIcon/TextFildwithAddIcon';
import { setHeaders } from "../../utils";




function Modalsetparameters({ isOpen, onClose, Idd }) {
 // const [fName, setfName] = useState(null);
 // const [lName, setlName] = useState(null);
 // const [nName, setnName] = useState(null);
 //// const [medicalLicense, setmedicalLicense] = useState(null);
  //const famousCities = ["رشت", "زاهدان", "تهران", "مشهد", "تبریز"];
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [paremeterLink, setparemeterLink] = useState('');
  const [parameters, setParameters] = useState(null)

  const initialFileData = {
    FileName: "",
    FileContent: "",
    FileTitle: "",
    FileDescription: "",
    FilesAddress: "",
    FileType: "",
    isImage: false,
  };
  
  const initialFileList = [
    { ...initialFileData },
    // اگر نیاز به افزودن اشیاء بیشتری دارید، می‌توانید اینجا ادامه دهید
  ];
  const [educationFilenewList, seteducationFilenewList] = useState([]);
  const [educationFileList, setEducationFIleList] = useState(initialFileList);
  const handleFileChange = (files) => {
    setSelectedFiles(files);
    };
//


  const [exercisesData, setExercisesData] = useState({
    name: "",
    subject1: "",
    subject2: "",
    preparation: "",
    execution: "0",
  });

  const convertGregorianToShamsi = (gregorianDate) => {
    // Use jalali-moment to convert Gregorian to Shamsi
    const shamsiDate = jalaliMoment(gregorianDate).format('jYYYY-jMM-jDD');
    return shamsiDate;

  };
  useEffect(() => {
    setSelectedFiles([]);
    setparemeterLink('');
    setParameters([]);
    if (Idd>0) {
      
      setparemeterLink(`https://api.bettermove.ir/api/v1/Exercise/${Idd}/ExerciseParameters`);
      const headers = setHeaders();
      axios
        .get(config.hostname+`/api/v1/Exercise/${Idd}`, { headers })
        .then((response) => {
          const exercisesDataaa = response.data;
          console.log(exercisesDataaa.exercisesDate);
        setExercisesData({
          name: exercisesDataaa.name,
          subject1: exercisesDataaa.subject1,
          subject2: exercisesDataaa.subject2,
          preparation: exercisesDataaa.preparation,
          execution: exercisesDataaa.execution,

        }); 
        })
        .catch(error => {
          console.error('خطا در درخواست به API: ', error);

        });

        const initialGregorianDate = '2023-01-01';
        
        // Convert the initial Gregorian date to Shamsi (Jalali)
        //const selectedMiladiDate = exercisesData.exercisesDate;
       //new
/*
axios
.get(config.hostname+ `/api/v1/Exercise/${Idd}/ExerciseFiles`, { headers })
//.get(`https://api.bettermove.ir/api/v1/Education/2/EducationFiles`, { headers })
//.get(`https://api.bettermove.ir/api/v1/Exercise/2/ExerciseFiles`, { headers })
.then((response) => {
  const educationfilData = response.data;

  const formattedData = educationfilData.map((item) => ({
    FileName: item.fileName,
    FileContent: item.fileContent,
    FileTitle:item.fileTitle,
    FileDescription: item.fileDescription,
    FilesAddress: item.filesAddress,
    FileType: item.fileType,
    isImage:item.isImage
   
  }));
  seteducationFilenewList(formattedData);
  console.log(formattedData);
  //console.log("educationFilenewList", educationFilenewList);
})
.catch(error => {
  console.error('خطا در درخواست به API: ', error);

});
*/
//end new 
    }
    else
    {

   setExercisesData({
    name: "",
          subject1: "",
          subject2: "",
          preparation: "",
          execution: "",
  });

    }
  }, [Idd]);

  const updateUser = async () => {
  
 
    try {
  
      const headers = setHeaders();
      const response = await axios.put(
        config.hostname+ `/api/v1/Exercise`,
        {
          keyID:Idd,
          name: exercisesData.name,
          subject1: exercisesData.subject1,
          subject2: exercisesData.subject2,
          preparation: exercisesData.preparation,
          execution: exercisesData.execution,
          otherDescription:exercisesData.execution,
          exercisesCategoryID:-1,

        },
        { headers }
      );

      console.log("ویرایش با موفقیت انجام شد", response.data);

      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  const insertuser = async () => {
    try {
      const filesData = [];
      const promises = selectedFiles.map(async (file) => {
        return new Promise((resolve) => {
          const fileData = new FormData();
          const reader = new FileReader();
          reader.onload = () => {
            const fileBytes = new Uint8Array(reader.result);       
          //  fileData.append('fileContent', fileBytes);
          //  fileData.append('fileName', file.name);
          fileData.fileName = file.name;
      // fileData.append('fileName', file.name);
          fileData.fileContent = Array.from(fileBytes).join(',');
          fileData.FileTitle ="title";
          fileData.FileDescription ="description";
          fileData.FilesAddress ="";
          
          fileData.FileType= file.name.split('.').pop();
          fileData.isImage = fileData.FileType.toUpperCase() === "JPG" || fileData.FileType.toUpperCase() === "PNG";
          console.log("type",fileData.FileType);
         // fileData.append('FileTitle', "title");
          //fileData.append('FileDescription', "description"); 
             filesData.push(fileData);
              // چاپ محتوای filesData
            //console.log('filesDatayyyyy', Array.from(filesData.entries()));
  
            // چاپ محتوای فایل فعلی
           //console.log('fileDataxxxx:', Array.from(fileData.entries()));
            resolve();
           // حتماً بعد از اضافه کردن فایل به filesData فراخوانی شود
             };
          reader.readAsArrayBuffer(file);
        });
      });
  
      // منتظر شدن اتمام تمام Promise ها (خواندن تمام فایل‌ها)
      await Promise.all(promises);
   
   //console.log('بایت‌های فایل:', filesData);
  
  
  
      const confirmDelete5 = window.confirm(
        "rrr" + selectedFiles.length + "rrr" + filesData.length
      );
      if (confirmDelete5) {}
  
      const headers = setHeaders();
  
      const response = await axios.post(
        config.hostname + `/api/v1/Exercise/${Idd}/ExerciseFiles`,       
        filesData,
        { headers }
      );
  
      console.log("ویرایش با موفقیت انجام شد", response.data);
  
      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };





  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600, 
          padding: 2
        }}
      >
        <div style={{ flex: 1, padding: '0 10px' }}>
          <h2>{Idd>0? "تعیین پارامتر های تمرین" : "تعیین پارامتر های تمرین"}</h2>
          <hr style={{ borderTop: '2px solid blue', margin: '10px 0' }} />
         
     
<TextField
  itemID="exercisesName"
  label="عنوان تمرین"
  value={exercisesData.name}
  onChange={(e) =>
    setExercisesData({ ...exercisesData, name: e.target.value })
  }
  fullWidth
  style={{ marginTop: '10px' }}
  dir="rtl"
  InputProps={{
    readOnly: true, // اضافه کردن این خط
    
  }}
/>

<TextField
  itemID="exercisesSubject1"
  label="عنوان مرحله اماده سازی"
  value={exercisesData.subject1}
  onChange={(e) =>
    setExercisesData({ ...exercisesData, subject1: e.target.value })
  }
  fullWidth
  style={{ marginTop: '10px',display:'none' }}
  dir="rtl"
  InputProps={{
    readOnly: true, // اضافه کردن این خط
    
  }}
/>

<TextField
  itemID="exercisesPreparation"
  label="توضیحات آماده سازی"
  value={exercisesData.preparation}
  onChange={(e) =>
    setExercisesData({ ...exercisesData, preparation: e.target.value })
  }
  fullWidth
  style={{ marginTop: '10px',display:'none' }}
  dir="rtl"
  InputProps={{
    readOnly: true, // اضافه کردن این خط
    
  }}
/>

<TextField
  itemID="exercisesSubject2"
  label="عنوان مراحل اجرا"
  value={exercisesData.subject2}
  onChange={(e) =>
    setExercisesData({ ...exercisesData, subject2: e.target.value })
  }
  fullWidth
  style={{ marginTop: '10px',display:'none' }}
  dir="rtl"
  InputProps={{
    readOnly: true, // اضافه کردن این خط
    
  }}
/>

<TextField
  itemID="exercisesExecution"
  label="توضیحات اجرای تمرین"
  value={exercisesData.execution}
  onChange={(e) =>
    setExercisesData({ ...exercisesData, execution: e.target.value })
  }
  fullWidth
  style={{ marginTop: '10px',display:'none' }}
  dir="rtl"
  InputProps={{
    readOnly: true, // اضافه کردن این خط
    
  }}
/>


<TextField
  itemID="exercisesCategoryID"
  label="شناسه دسته‌بندی آموزش"
  value={exercisesData.exercisesCategoryID}
  onChange={(e) =>
    setExercisesData({
      ...exercisesData,
      exercisesCategoryID: e.target.value,
    })
  }
  fullWidth
  style={{ marginTop: '10px',display:'none' }}
  dir="rtl"
  InputProps={{
    readOnly: true, // اضافه کردن این خط
    
  }}
/>

 {/* section 3 Form information */}
 <TextFieldwithAddIcon setParameters = {setParameters} link={paremeterLink}
  keyID={Idd}/>
        {/* setParameters = {setParameters} link={paremeterLink}   keyID={Idd}
      section 4 Detail*/}


{/*
<div>
<h2> فایل های پیوست شده :</h2>
<DisplayImage educationFileData={educationFilenewList}></DisplayImage>

</div>
<h2>لیست انتظار آپلود  :  </h2>
<ul>
  {selectedFiles.map((file, index) => (
    <li key={index}>{file.name}</li>
  ))}
</ul>
  
<FileUploader3 onChange={handleFileChange} />

<Button variant="contained"  onClick={Idd>0? insertuser:insertuser} sx={{ marginTop: 2 }}>
          {Idd>0 ? "افزودن پارامتر ها" : "افزودن پارامتر ها"}
</Button>*/}
        </div>
      </Paper>
    </Modal>
  );
  
  
  
}
//style={{ marginTop: '10px', display: Idd > 1 ? 'block' : 'none'}}

//<div  style={{ marginTop: '10px' ,height:'200px'}} >

  //<PersianDatePicker   onDateChange={handleGregorianDateChange} />

//{ نمایش مقدار به‌روزرسانی شده در App.js }
//{selectedGregorianDate && <p>Gregorian Date in App.js: {selectedGregorianDate}</p>}
  

//</div


export default Modalsetparameters;
