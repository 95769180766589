import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import SelectBox from "./SelectBox";
import axios from "axios";
import config from "../../config";
import { setHeaders } from "../../utils";
import Swal from "sweetalert2";

export default function NewTicket({
  isNewTicketBoxOpen,
  setIsNewTicketBoxOpen,
  getAllTickets,
}) {
  const ticketInitialValue = {
    subject: "",
    content: "",
  };

  const [ticketReceiver, setTicketReceiver] = useState(null);
  const [ticketReceiverType, setTicketReceiverType] = useState("doctor");
  const [newTicket, setNewTicket] = useState(ticketInitialValue);
  const [allContacts, setAllContacts] = useState([]);

  useEffect(() => {
    getAllContacts(ticketReceiverType == "doctor" ? "Physician" : "Patient");
  }, [ticketReceiverType]);

  function getAllContacts(contactType) {
    const headers = setHeaders();
    axios
      .get(config.hostname + `/api/v1/${contactType}/1,10000`, { headers })
      .then((response) => {
        console.log("contact", response);

        setAllContacts(
          contactType === "Physician"
            ? response.data.physicianModelList
            : response.data.patientModelList
        );
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function closeNewTicketBox() {
    setIsNewTicketBoxOpen(false);
    setNewTicket(ticketInitialValue);
  }

  function sendNewTicket() {
    const headers = setHeaders();

    const newChat = {
      keyID: 0,
      subject: newTicket.subject,
      description: "string",
      reciverID: ticketReceiver,
      priority: 0,
      status: 0,
      newMessage: newTicket.content,
    };

    console.log("new chat", newChat);

    axios
      .post(config.hostname + `/api/v1/Ticket`, newChat, { headers })
      .then((response) => {
        console.log("contact", response);
        closeNewTicketBox();
        Swal.fire({
          icon: "success",
          title: "تیکت با موفقیت ارسال شد.",
        });
        getAllTickets();
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  return (
    <Dialog
      className="new-ticket-modal"
      open={isNewTicketBoxOpen}
      onClose={closeNewTicketBox}
    >
      <Box className="top-new-ticket">
        <DialogTitle
          style={{
            fontFamily: "IRANSans",
            fontSize: "2rem",
          }}
        >
          تیکت جدید
        </DialogTitle>
        <IconButton
          className="btn-close-modal"
          style={{ marginLeft: "1rem" }}
          onClick={closeNewTicketBox}
        >
          <Cancel style={{ color: "rgba(176,56,60,255)", fontSize: "2rem" }} />
        </IconButton>
      </Box>

      <DialogContent>
        <div className="filter-section">
          <div className="filter-receiver-type">
            <SelectBox
              filterName="گیرنده تیکت"
              filterValue={ticketReceiverType}
              setFilterValue={setTicketReceiverType}
              menuItems={[
                { id: 1, name: "پزشک", value: "doctor" },
                { id: 2, name: "بیمار", value: "patient" },
              ]}
            />
          </div>

          <SelectBox
            filterName={`نام ${
              ticketReceiverType === "doctor" ? "پزشک" : "بیمار"
            }`}
            filterValue={ticketReceiver}
            setFilterValue={setTicketReceiver}
            menuItems={allContacts}
          />
        </div>
        <FormControl fullWidth margin="normal">
          <TextField
            label="موضوع"
            name="subject"
            value={newTicket.subject}
            onChange={(e) =>
              setNewTicket({
                ...newTicket,
                subject: e.target.value,
              })
            }
            variant="outlined"
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="توضیحات"
            name="content"
            value={newTicket.content}
            onChange={(e) =>
              setNewTicket({
                ...newTicket,
                content: e.target.value,
              })
            }
            variant="outlined"
            fullWidth
            multiline
            rows={4}
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={sendNewTicket} className="btn-send">
          ارسال پیام
        </Button>
      </DialogActions>
    </Dialog>
  );
}
