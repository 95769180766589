import React, { useState, useEffect, useContext } from "react";
import { Modal, Paper, TextField, Button } from "@mui/material";
import MyTree from "./TreeView.jsx";
import axios from "axios";
import config from "../../config.js";
import ListView from "./ListView.jsx";
import { setHeaders } from "../../utils.js";

function ModalGroup({ isOpen, onClose, Idd }) {

  const [educationsData, setEducationsData] = useState({
    title: "",
  });

  const [fetchedtdataset, setfetchedtdataset] = useState([]);

  function mapdatatofetcheddata(rawData) {
    const fetchedData = [];

    const groupByKeyID = {};

    // گروه‌بندی بر اساس keyID
    rawData.forEach((item) => {
      if (item.fK_ParentID === -1) {
        groupByKeyID[item.keyID] = {
          keyID: item.keyID,
          name: item.ctgName,
          children: [],
        };
      } else {
        const parent = groupByKeyID[item.fK_ParentID];
        if (parent) {
          parent.children.push({
            keyID: item.keyID,
            name: item.ctgName,
          });
        }
      }
    });

    // افزودن داده‌های گروه‌بندی شده به آرایه fetchedData
    Object.values(groupByKeyID).forEach((group) => {
      fetchedData.push(group);
    });
    setfetchedtdataset(fetchedData);
  }

  useEffect(() => {
    console.log("idd", Idd);
    if (Idd) {
      const headers = setHeaders();

      axios
        .get(config.hostname + `/api/v1/Education/${Idd}`, { headers })
        .then((response) => {
          const exercisesctgDataaa = response.data;
          setEducationsData({
            title: exercisesctgDataaa.title,
          });
        })
        .catch((error) => {
          console.error("خطا در درخواست1 به API: ", error);
        });

      axios
        .get(config.hostname + "/api/v1/EducationCtg", { headers })
        .then((response) => {
          const ctgData = response.data;
          console.log(ctgData, "another test");
          mapdatatofetcheddata(ctgData);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    }
  }, [Idd]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          height: 500,
          padding: 2,
        }}
      >
        <div
          style={{
            width: "300px",
            height: "100%",
            marginLeft: "10px",
            backgroundColor: "white",
          }}
        >
          <div> گروه ها</div>
          <MyTree fetchedData={fetchedtdataset} />
        </div>
        <div
          style={{
            flex: "1",
            padding: "0 10px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2>دسته بندی آموزش</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
          {/* Your main content here */}
          {/* ... */}
          <TextField
            itemID="exercisesName"
            label="عنوان آموزش"
            value={educationsData.title}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          {/* ... (other form fields) */}
          <ListView idd={Idd} />

          {/* <Button
          variant="contained"
          onClick={Idd > 0 ? insertuser : insertuser}
          sx={{ marginTop: 2 }}
        > */}
          {/*   <Button
        variant="contained"
        onClick={posteListeGroupe}
        sx={{ marginTop: 2 }}
      >
          Idd > 0 ? "ذخیره تگ ها" : "ذخیره تگ ها"
        </Button>*/}
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalGroup;
