import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Button } from "@mui/material";
import ModalPhy from "./modal-phy";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import config from "../../config";
import Searchbox from "../../Components/Searchbox/Searchbox";
import { setHeaders } from "../../utils";

function PhysicianList() {
  const [physicianList, setPhysicianList] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingPhysicianId, setEditingPhysicianId] = useState(null);

  const handleEditClick = (physicianId) => {
    setEditingPhysicianId(physicianId);

    setEditModalOpen(true);
  };

  const handleafterEdit = () => {
    setEditModalOpen(false);
    refreshlist();
  };

  const refreshlist = () => {
    const headers = setHeaders();
    axios
      .get(config.hostname + "/api/v1/Physician/1,10000", { headers })
      .then((response) => {
        const physicianData = response.data.physicianModelList;

        const formattedData = physicianData.map((item) => ({
          id: item.keyID,
          fName: item.fName,
          lName: item.lName,
          nName: item.nName,
          medicalLicense: item.medicalLicense,
          nationalCode: item.nationalCode,
          birthDate: item.birthDate,
          gender: item.gender,
          mobile1: item.mobile1,
          mobile2: item.mobile2,
          email: item.email,
          description: item.description,
          speciallization: item.speciallization,
        }));
        setPhysicianList(formattedData);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  useEffect(() => {
    const headers = setHeaders();
    axios
      .get(
        config.hostname +
          "/api/v1/Physician/GetNewUserByPage?pageNumber=1&pageSize=10",
        { headers }
      )
      .then((res) => console.log("rest", res.data));
  }, []);

  useEffect(() => {
    const headers = setHeaders();
    axios
      .get(config.hostname + "/api/v1/Physician/1,10000", { headers })
      .then((response) => {
        const physicianData = response.data.physicianModelList;

        const formattedData = physicianData.map((item) => ({
          id: item.keyID,
          fName: item.fName,
          lName: item.lName,
          nName: item.nName,
          medicalLicense: item.medicalLicense,
          nationalCode: item.nationalCode,
          birthDate: item.birthDate,
          gender: item.gender,
          mobile1: item.mobile1,
          mobile2: item.mobile2,
          email: item.email,
          description: item.description,
          speciallization: item.speciallization,
        }));
        setPhysicianList(formattedData);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }, []);

  const deletePhysician = (physicianId) => {
    const confirmDelete = window.confirm(
      "آیا مطمئن هستید که می‌خواهید این پزشک را حذف کنید؟"
    );

    if (confirmDelete) {
      const headers = setHeaders();
      axios
        .delete(config.hostname + `/api/v1/Physician/${physicianId}`, {
          headers,
        })

        .catch((error) => {
          console.error("خطا در درخواست به API برای حذف پزشک: ", error);
        });
      refreshlist();
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "شناسه ",
      width: 90,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {
              <Link
                className="link"
                onClick={() => handleEditClick(params.row.id)}
              >
                <EditIcon className="userListEdit" />
              </Link>
            }
            {
              <Link className="link">
                <DeleteIcon
                  className="userListTrash"
                  onClick={() => deletePhysician(params.row.id)}
                />
              </Link>
            }
          </>
        );
      },
    },
    {
      field: "fName",
      headerName: "نام",
      width: 150,
    },
    {
      field: "lName",
      headerName: "نام خانوادگی",
      width: 150,
    },
    {
      field: "nName",
      headerName: "نام مستعار",
      width: 150,
    },
    {
      field: "medicalLicense",
      headerName: "مجوز پزشکی",
      width: 200,
      hide: true,
    },
    {
      field: "nationalCode",
      headerName: "کد ملی",
      width: 150,
      hide: true,
    },
    //{
    //  field: "birthDate",
    //  headerName: "تاریخ تولد",
    //width: 150,
    // hide: true

    //  },
    {
      field: "gender",
      headerName: "جنسیت",
      width: 120,
      hide: true,
    },
    {
      field: "mobile1",
      headerName: "موبایل",
      width: 150,
    },
    {
      field: "mobile2",
      headerName: "موبایل 2",
      width: 150,
      hide: true,
    },
    {
      field: "email",
      headerName: "ایمیل",
      width: 200,
    },
    {
      field: "description",
      headerName: "توضیحات",
      width: 150,
      hide: true,
    },
    {
      field: "speciallization",
      headerName: "تخصص",
      width: 150,
      hide: true,
    },
  ];

  return (
    <>
      <div sx={{ height: 520, width: "90%" }} className="userList">
        <div className="education-actions">
          <Button onClick={() => handleEditClick(0)} variant="contained">
            افزودن
            <AddCircleOutlineIcon className="userListInsert" />
          </Button>
          <Searchbox />
        </div>
        <DataGrid
          rows={physicianList}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          checkboxSelection
        />
      </div>

      <ModalPhy
        isOpen={isEditModalOpen}
        onClose={() => handleafterEdit()}
        Idd={editingPhysicianId}
      />
    </>
  );
}

export default PhysicianList;
